// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-components-post-js": () => import("./../../../src/pages/components/Post.js" /* webpackChunkName: "component---src-pages-components-post-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-001-intro-js": () => import("./../../../src/pages/posts/001-intro.js" /* webpackChunkName: "component---src-pages-posts-001-intro-js" */),
  "component---src-pages-type-demo-js": () => import("./../../../src/pages/type-demo.js" /* webpackChunkName: "component---src-pages-type-demo-js" */)
}

